@font-face {
  font-family: 'WebComponentsIcons';
  src: url('../../node_modules/@progress/kendo-font-icons/dist/kendo-font-icons.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('../assets/fonts/nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Light';
  src: url('../assets/fonts/nunito/Nunito-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('../assets/fonts/nunito/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mark-Pro';
  src: url('../assets/fonts/mark-pro/Mark-Pro.ttf') format('woff2');
}
/*Font Awesome styles starts here */
@import '../assets/fonts/font-awesome/font-awesome-pro.min.scss';

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(../assets/fonts/font-awesome/webfonts/fa-brands-400.eot);
  src:
    url(../assets/fonts/font-awesome/webfonts/fa-brands-400.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/font-awesome/webfonts/fa-brands-400.woff2) format('woff2'),
    url(../assets/fonts/font-awesome/webfonts/fa-brands-400.woff) format('woff'),
    url(../assets/fonts/font-awesome/webfonts/fa-brands-400.ttf) format('truetype'),
    url(../assets/fonts/font-awesome/webfonts/fa-brands-400.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(../assets/fonts/font-awesome/webfonts/fa-light-300.eot);
  src:
    url(../assets/fonts/font-awesome/webfonts/fa-light-300.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/font-awesome/webfonts/fa-light-300.woff2) format('woff2'),
    url(../assets/fonts/font-awesome/webfonts/fa-light-300.woff) format('woff'),
    url(../assets/fonts/font-awesome/webfonts/fa-light-300.ttf) format('truetype'),
    url(../assets/fonts/font-awesome/webfonts/fa-light-300.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../assets/fonts/font-awesome/webfonts/fa-regular-400.eot);
  src:
    url(../assets/fonts/font-awesome/webfonts/fa-regular-400.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/font-awesome/webfonts/fa-regular-400.woff2) format('woff2'),
    url(../assets/fonts/font-awesome/webfonts/fa-regular-400.woff) format('woff'),
    url(../assets/fonts/font-awesome/webfonts/fa-regular-400.ttf) format('truetype'),
    url(../assets/fonts/font-awesome/webfonts/fa-regular-400.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../assets/fonts/font-awesome/webfonts/fa-solid-900.eot);
  src:
    url(../assets/fonts/font-awesome/webfonts/fa-solid-900.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/font-awesome/webfonts/fa-solid-900.woff2) format('woff2'),
    url(../assets/fonts/font-awesome/webfonts/fa-solid-900.woff) format('woff'),
    url(../assets/fonts/font-awesome/webfonts/fa-solid-900.ttf) format('truetype'),
    url(../assets/fonts/font-awesome/webfonts/fa-solid-900.svg#fontawesome) format('svg');
}

.fal,
.far {
  font-family: 'Font Awesome 5 Pro';
}

.far {
  font-weight: 400;
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

/*Font Awesome styles end here */
