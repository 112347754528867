@import '../../node_modules/@progress/kendo-theme-default/dist/all.css';
@import '../../node_modules/@progress/kendo-font-icons/dist/index.css';

.k-icon {
  font-family: 'WebComponentsIcons';
}

.k-loader-primary {
  color: var(--s3-color-primary);
}

.k-picker-solid,
.k-picker-solid:hover,
.k-input-solid,
.k-input-solid:hover {
  background: #fff;
  padding: var(--s3-input-padding);
  border-radius: var(--s3-border-radius);
  border: var(--s3-input-border);
}

.k-input-button,
.k-input-button:hover {
  background: none;
  border: none;
}

.k-picker-solid,
.k-input-solid {
  transition: border-radius 100ms;
}

.k-picker-solid.k-focus[aria-expanded='true'],
.k-input-solid.open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.k-list-container,
.k-list {
  border-bottom-left-radius: var(--s3-border-radius) !important;
  border-bottom-right-radius: var(--s3-border-radius) !important;
}

.k-list-md .k-list-item {
  padding: 12px 25px;
}

.k-list-item.k-selected,
.k-list-item.k-selected,
.k-list-item.k-selected:hover {
  background: var(--s3-color-default-light);
  color: var(--s3-input-font-color);
}

.k-input-value-text {
  color: var(--s3-input-font-color);
}

.k-list-item.k-focus {
  box-shadow: none; //inset 0 0 0 1px rgb(0 0 0 / 12%);
}

.k-popup {
  border-color: var(--s3-border-color);
}

.k-calendar .k-nav-today,
.k-calendar .k-calendar-nav-today,
.k-calendar .k-nav-today:hover,
.k-calendar .k-calendar-nav-today:hover {
  color: var(--s3-color-default);
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link,
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner:hover,
.k-calendar .k-calendar-td.k-selected .k-link:hover {
  background-color: var(--s3-color-default-light);
  color: var(--s3-color-default);
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: var(--s3-color-primary-10);
}

.k-link,
.k-link:hover {
  color: var(--s3-color-default);
}

.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
  background-image: none;
}

.k-calendar .k-range-split-start::after:hover,
.k-calendar .k-range-split-end::after:hover {
  background-image: none;
}

/////////////////////////////////////////////////////
//////Kendo grid override///////////////////////////

.k-grid {
  border: none !important;
  border-spacing: 0px !important;
}

.k-grid .k-detail-row .k-detail-cell {
  padding: 0;
}

.k-master-row {
  height: 50px;
  background-color: var(--s3-btn-font-color) !important;
}

.k-master-row:hover {
  background-color: var(--s3-btn-font-color) !important;
}

.k-detail-row {
  box-shadow: inset 0px 15px 15px -3px black;
}

.k-grid-header {
  padding: 0px !important;
}

.k-grid-header .k-header {
  cursor: default;
  border-right: 1px solid var(--s3-btn-font-color);
  justify-content: center;

  .k-cell-inner {
    height: 48px;
  }
}

.k-grid .k-hierarchy-cell .k-icon {
  display: none;
}

.k-grid .k-hierarchy-col {
  width: 0;
}

.k-touch-action-auto {
  border-bottom: 3px solid var(--s3-btn-font-color);
  border: none !important;
}

.k-cell-inner > .k-link {
  color: var(--s3-btn-font-color);
}

/////////////////////////////////////////////////////////////
//////kendo-toast message override///////////////////////////

kendo-notification-container {
  position: absolute;
  z-index: 999999999;
}

//slider

.k-slider .k-slider-selection {
  background-color: var(--s3-color-secondary);
}

.k-slider .k-draghandle {
  background-color: var(--s3-color-secondary);
  border-color: var(--s3-color-secondary);
  width: 20px;
  height: 14px;
  border-radius: 25%;
}

.k-slider .k-draghandle:hover {
  border-color: var(--s3-color-secondary);
  background-color: var(--s3-color-secondary);
  width: 20px;
  height: 14px;
  border-radius: 25%;
}

.k-slider .k-draghandle:active,
.k-slider .k-draghandle.k-pressed {
  border-color: var(--s3-color-secondary);
  background-color: var(--s3-color-secondary);
  width: 20px;
  height: 14px;
  border-radius: 25%;
}

.k-slider .k-draghandle:focus,
.k-slider .k-draghandle.k-focus {
  box-shadow: none;
}

/////////////////////////////////////
/////kendo dropdown//////////////////

.k-list-optionlabel {
  display: none;
}

.s3-site-control {
  border: none;
  box-shadow: none !important;

  &:hover {
    border: none;
    box-shadow: none;
  }

  .k-input-button {
    display: none;
  }

  .k-input-value-text::before {
    display: none;
  }
}

.k-other-month .k-link {
  display: none;
}

////////////kendo-multi select checkbox/////////////////////////////
.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--s3-color-primary);
  color: var(--s3-white);
  background-color: var(--s3-color-primary);
}

///////kendo-date-range ////////////////////
.k-daterange-picker,
.k-daterangepicker {
  gap: 0px;
}

.k-button-md.k-icon-button .k-button-icon .k-icon,
.k-svg-icon {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400 !important;
  font-size: 18px;
}

kendo-svgicon svg {
  display: none;
}

kendo-svg-icon svg {
  display: none;
}

.k-button-md.k-icon-button .k-button-icon .k-icon,
.k-svg-icon::before {
  content: '\f107' !important;
}

.k-button-icon.k-icon {
  font-family: 'Font Awesome 5 Pro' !important;
  font-weight: 400 !important;
  font-size: 18px;
}

.k-i-caret-alt-down::before {
  content: '\f107' !important;
}

//remove padding for kendo dropdown
.k-picker-md .k-input-inner {
  padding-inline: 0px;
}

.k-input-md .k-input-inner {
  padding-inline: 0px;
}

.k-arcgauge-label {
  margin-top: 50px;
}

.k-chart-surface {
  width: 230px;
}

.k-multiselect:after {
  font-family: 'Font Awesome 5 Pro' !important;
  content: '\f107' !important;
  position: absolute;
  right: 32px;
  font-size: 18px;
}

.k-multiselect .opened:after {
  font-family: 'Font Awesome 5 Pro' !important;
  content: '\f106' !important;
  position: absolute;
  margin-top: 2px;
  right: 32px;
  font-size: 18px;
}
