@import '../../node_modules/bootstrap/scss/bootstrap';

.progress {
  background-color: var(--s3-color-secondary-light);
  .progress-bar {
    background-color: var(--s3-color-secondary);
  }
}

.s3-custom-modal .modal-header {
  border-bottom: none;
}

.s3-custom-modal-backdrop {
  z-index: 999999 !important;
}

.s3-custom-modal {
  z-index: 999999;
}
